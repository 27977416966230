<template>
    <div class="container-fluid pl-5 pr-5 content-container">
        <axios :url="links.index" request="get" :data="{}" :trigger="triggers.index" v-on:success="setRoutes"
               v-on:request="triggers.index=false;"></axios>

        <axios :url="links.add" request="post" :data="storeData" :trigger="triggers.store"
               v-on:success="routeSaved" v-on:error="routeValidationError" v-on:request="triggers.store=false"></axios>

        <axios :url="links.update" request="post" :data="update_route" :trigger="triggers.update"
               v-on:success="routeUpdated" v-on:error="routeValidationError"
               v-on:request="triggers.update=false"></axios>

        <axios :url="links.update"></axios>
        <div class="row">
            <div class="col-sm-12 bg-light shadow  p-3 text-left">
                <p class="heading">Routes</p>
                <hr/>
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <tr>
                            <td>#</td>
                            <td>Name</td>
                            <td></td>
                        </tr>
                        <tr v-for="(route,index) in routes" v-bind:key="index">
                            <td>{{index + 1}}</td>
                            <td>
                                <div class="form-group" v-if="update_route.name.length>0 && update_route.id==route.id">
                                    <input type="text"
                                           class="form-control w-12"
                                           v-model="update_route.name">
                                    <span class="text-danger">{{getError('name')}}</span>
                                </div>
                                <span v-else="">{{route.name}}</span>
                            </td>
                            <td>
                                <button v-if="update_route.id==route.id" class="btn btn-outline-info btn-sm"
                                        @click="updateRoute()">Update
                                </button>
                                <button v-else="" class="btn btn-outline-info btn-sm" @click="editRoute(index);">Edit
                                </button>
                            </td>
                        </tr>

                        <tr>
                            <td>{{routes.length + 1}}</td>
                            <td><input type="text" v-model="storeData.name" class="form-control w-25"
                                       placeholder="Route Name"> <span v-if="save"
                                                                       class="text-danger">{{getError('name')}}</span>
                            </td>
                            <td><input type="button" class="btn btn-outline-info "
                                       @click="triggers.store=true;save=true;"
                                       value="Save Route"></td>
                        </tr>

                    </table>
                </div>
            </div>
        </div>
    </div>

</template>
<script>
    import forms from '@/forms';
    import {Paths} from '@/paths';
    import axios from '@/views/actions/Axios';
    export default{
        extends: forms,
        components: {axios},
        data(){
            return {
                routes: [],
                trigger: false,
                save: false,
                links: {
                    index: '',
                    add: '',
                    update: '',
                },
                triggers: {
                    index: false,
                    store: false,
                    update: false,
                },
                storeData: {
                    name: '',
                },
                update_route: {
                    name: '',
                    id: '',
                    index: 0,
                    _method: 'put'
                }
            }
        },
        methods: {
            setRoutes(data){
                this.routes = data;
            },
            routeSaved(data){

                this.save = false;
                this.routes.push({'id': data.data.id, 'name': data.data.name});
                this.storeData.name = '';
            },
            routeValidationError(data){
                this.triggers.store = false;
                this.setErrors(data.response);
//                console.log(data);
            },
            editRoute(index){
                let route = this.routes[index];
                this.update_route.name = route.name;
                this.update_route.id = route.id;
                this.update_route.index = index;
            },
            updateRoute(){
                this.triggers.update = true;

            },
            clearUpdateRouteData(){
                this.update_route.index = -1;
                this.update_route.id = 0;
                this.update_route.name = "";
            },
            routeUpdated(response){
                this.routes[this.update_route.index].name = response.data.name;
                this.clearUpdateRouteData();

            }
        },
        mounted(){
            this.links.index = Paths.routes.index;
            this.links.add = Paths.routes.store;
            this.links.update = Paths.routes.update;
            this.triggers.index = true;
        }
    }
</script>